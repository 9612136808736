import React, { useState } from "react"
import { getValue } from "../../utils"
import Form from "../Form"
import Message from "../UI/Message"

import { connect } from "react-redux"
import { update } from "../../redux/actions/currentUserActions"
import { getErrorMessage } from "../../utils"

const PhoneUpdate = ({ dispatch, user }) => {
  const [messages, setMessages] = useState([])

  const formData = {
    formId: "update",
    successMessage: "Votre compte a bien été mis à jour.",
		loadingMessage: "Modification du compte en cours",
    elements: [
      {
        type: "text",
        name: "field_phone",
        default: getValue(user, "field_phone", "number", ""),
        label: "Merci de renseigner un numéro de téléphone portable:",
        required: true,
        props: {
          width: "100%",
        },
      },
      {
        type: "submit",
        label: "Mettre à jour",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues, formdata, submitSuccess,submitError) => {
    if (formValues.current_pass === "") {
      delete formValues.pass
      delete formValues.current_pass
      delete formValues.mail
    }
    formValues.field_phone = {
      und: [
        {
          number: formValues.field_phone,
          country_codes: "nc",
          extension: null,
        },
      ],
    }

    setMessages([])
    dispatch(update(formValues, user.uid)).then(action => {
      if (action.type === "USER_UPDATE_SUCCESS") {
        // submitSuccess()
        // dispatch(user_create_code(user.uid))
        // setMessages([...messages, { status: "success", value: "Votre compte a bien été mis à jour." }]);
      }
      if (action.type === "USER_UPDATE_FAIL") {
        // submitSuccess()
        submitError()
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={key}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      <Form data={formData} onSubmit={onSubmit} />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(PhoneUpdate)
