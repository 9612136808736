import React, { useState } from "react"
import Modal from "styled-react-modal"
import { Box, Heading, Flex, Button } from "rebass/styled-components"

import IconPlan from "./icons/IconPlan"
const StyledModal = Modal.styled`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity};
  transition: opacity ease 300ms;
  width: 100%;
`

const ModalCustom = ({
  title,
  containerSx,
  children,
  isOpen,
  setIsOpen,
  ...props
}) => {
  isOpen = isOpen || false
  containerSx = containerSx || {}
  title = title || ""
  const [opacity, setOpacity] = useState(0)

  const close = () => {
    setIsOpen(false)
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 200)
    })
  }

  return (
    <StyledModal
      isOpen={isOpen}
      beforeOpen={() => setOpacity(0)}
      afterOpen={() => setOpacity(1)}
      beforeClose={beforeClose}
      opacity={opacity}
      backgroundProps={{ opacity }}
      onBackgroundClick={close}
      onEscapeKeydown={close}
      {...props}
    >
      <Box px={[2, 2, 2, 0]} maxWidth="100vw" width="100%">
        <Box
          variant="container"
          bg="white"
          pb={[6, 6, 0]}
          sx={{
            borderRadius: "6px",
            position: "relative",
            maxHeight: "90vh",
            overflow: "scroll",
            pl: "10px !important",
            pr: "10px !important",
            minWidth: ["100%", "100%", "100%", "900px"],
            maxWidth: ["100%", "100%", "100%", "940px", "960px"],
            ...containerSx,
          }}
        >
          <Flex py={3} alignItems="flex-start" justifyContent="space-between">
            <Heading as="span">{title}</Heading>
            <Button variant="primary" onClick={close} p={2}>
              <IconPlan icon="close" size="24" sx={{ minWidth: "30px" }} />
            </Button>
          </Flex>

          {children}
        </Box>
      </Box>
    </StyledModal>
  )
}

export default ModalCustom
