import React from "react"
import Page from "../../templates/Page"
import { connect } from "react-redux"
import Loader from "../../components/UI/Loader"

import User from "../../components/User"
import { Box } from "rebass/styled-components"
import Block from "../../components/UI/poi/Block"
import CancelAccount from "../../components/User/CancelAccount"

const MonCompte = ({ location, loading, user }) => {
  return (
    <Page title="Supprimer mon compte" location={location} forceMap={false}>
      {!loading && user && (
        <>
          <Box>
            <Block title="Supprimer mon compte">
              <p>
                Nous souhaitons vous informer qu'en procédant à la suppression
                de votre compte, toutes les données associées seront
                définitivement effacées. Cela inclut vos informations
                personnelles, vos historiques de navigation, ainsi que tout
                contenu que vous avez pu partager ou enregistrer sur notre
                plateforme.
              </p>
              <p>
                Si vous êtes sûr(e) de vouloir continuer et supprimer votre
                compte, veuillez cliquer sur le bouton "Supprimer mon compte"
                ci-dessous.
              </p>
              <CancelAccount />
            </Block>
          </Box>
        </>
      )}
      {!loading && !user && <User autoLoggin={true} />}
      {loading && <Loader message=" " />}
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  loading: state.currentUser.loading,
})
export default connect(mapStateToProps)(MonCompte)
