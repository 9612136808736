import React, { useState } from "react"
import { Box } from "rebass/styled-components"
import Form from "../Form"
import { useStaticQuery, graphql } from "gatsby"
import Message from "../UI/Message"
import { getErrorMessage } from "../../utils"

import { connect } from "react-redux"
import { subscribe } from "../../redux/actions/currentUserActions"

const Inscription = ({ dispatch, autoLoggin }) => {
  autoLoggin = autoLoggin || false
  const [messages, setMessages] = useState([])
  const [currentFormValue, setCurrentFormValue] = useState({})
  const data = useStaticQuery(graphql`
    query LesVilles2 {
      allLesVillesJson {
        nodes {
          nid
          title
        }
      }
    }
  `)

  const villeOptions = data.allLesVillesJson.nodes.map(function(ville) {
    return {
      value: ville.nid,
      label: ville.title,
    }
  })
  const options = [
    {
      value: "",
      label: "aucun",
    },
    ...villeOptions,
  ]
  const formData = {
    formId: "inscription",
    useCaptcha: true,
    successMessage:
      "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
    loadingMessage: "Création du compte en cours",
    submitLabel: "S'inscrire",
    elements: [
      {
        type: "text",
        name: "field_nom",
        default: currentFormValue.field_nom ? currentFormValue.field_nom : "",
        label: "Nom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "field_prenom",
        default: currentFormValue.field_nom
          ? currentFormValue.field_prenom
          : "",
        label: "Prénom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "textDate",
        name: "field_naissance",
        default: currentFormValue.field_naissance
          ? currentFormValue.field_naissance
          : "",
        label: "Date de naissance",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "mail",
        default: currentFormValue.field_nom ? currentFormValue.mail : "",
        label: "Email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "text",
        name: "mail-verif",
        default: currentFormValue.field_nom ? currentFormValue.mail : "",
        label: "Vérification email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function(values, data) {
          if (values["mail-verif"] !== values["mail"]) {
            return "les adresses emails ne correspondent pas."
          }
          return false
        },
      },
      {
        type: "text",
        name: "field_phone",
        default: currentFormValue.field_nom ? currentFormValue.field_phone : "",
        label: "Téléphone",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "select",
        name: "field_ville_ref",
        default: currentFormValue.field_nom
          ? currentFormValue.field_ville_ref
          : "",
        label: "Ville",
        required: true,
        options: options,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "markup",
        markup: <Box my={2} height="2px" bg="gray10" />,
        props: {
          width: 1,
        },
      },
      {
        type: "password",
        name: "pass",
        default: "",
        label: "Mot de passe",
        required: true,
        passwordmeter: "mail",
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "password",
        name: "pass-verif",
        default: "",
        label: "Confirmer le mot de passe",
        required: true,
        passwordchecker: "pass",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function(values, data) {
          if (values["pass-verif"] !== values["pass"]) {
            return "les mots de passe ne correspondent pas."
          }
          return false
        },
      },
      // {
      //   type: "checkbox",
      //   name: 'cgu',
      //   default: currentFormValue.cgu ? parseInt(currentFormValue.cgu) : 0,
      //   label: "",
      //   required: true,
      //   props: {
      //     width: '100%'
      //   },
      //   options: [
      //     {
      //       value: 1,
      //       label: <>J'accepte les <a href='/cgu' target='_blank' rel='noopener noreferrer'><Text color="primary">conditions générales d'utilisation</Text></a>.</>,
      //     }
      //   ]
      // },
      {
        type: "submit",
        label: "S'inscrire",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues, formdata, submitSuccess, submitError) => {
    setCurrentFormValue({ ...formValues })
    formValues.name = formValues.mail
    formValues.field_prenom = {
      und: [{ value: formValues.field_prenom, format: null, safe_value: "" }],
    }
    formValues.field_nom = {
      und: [{ value: formValues.field_nom, format: null, safe_value: "" }],
    }

    formValues.field_naissance = {
      und: [
        { value: formValues.field_naissance, format: null, safe_value: "" },
      ],
    }
    formValues.field_ville = {
      und: [
        {
          value: options.filter(
            ville => ville.value === formValues.field_ville_ref
          )[0].label,
          format: null,
          safe_value: "",
        },
      ],
    }
    formValues.field_ville_ref = { und: { nid: formValues.field_ville_ref } }
    formValues.field_phone = {
      und: [
        {
          number: formValues.field_phone,
          country_codes: "nc",
          extension: null,
        },
      ],
    }
    delete formValues["pass-verif"]
    // delete formValues['cgu'];
    formValues.autoLoggin = autoLoggin
    setMessages([])
    dispatch(subscribe(formValues, autoLoggin)).then(action => {
      if (action.type === "USER_SUBSCRIBE_SUCCESS") {
        // submitSuccess()
        if (autoLoggin) {
          // dispatch(getToken(false)).then(actionToken => {
          //   dispatch(currentSession()).then(sessionToken => {
          //     dispatch(login({ username: formValues.mail, password: formValues.pass }, true))
          //   })
          // })
        } else {
          setMessages([
            {
              status: "success",
              value:
                "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
            },
          ])
        }
      }
      if (action.type === "USER_SUBSCRIBE_FAIL") {
        submitError()
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={message.value}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      <Form data={formData} onSubmit={onSubmit} />
    </>
  )
}
export default connect()(Inscription)
