import React, { useState } from "react"
import Form from "../Form"
import Message from "../UI/Message"
import { getErrorMessage } from "../../utils"

import { connect } from "react-redux"
import { reset_password } from "../../redux/actions/currentUserActions"
const ResetPassword = ({ dispatch }) => {
  const [currentFormValue, setCurrentFormValue] = useState({})
  const [messages, setMessages] = useState([])
  const formDatareset = {
    formId: "resetpassword",
    successMessage:
      "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
    submitLabel: "Demander un nouveau mot de passe",
    elements: [
      {
        type: "text",
        name: "name",
        default: currentFormValue.name ? currentFormValue.name : "",
        label: "Nom d'utilisateur ou email",
        required: true,
      },
      {
        type: "submit",
        label: "Demander un nouveau mot de passe",
        props: {
          width: 1,
        },
        buttonProps: {
          width: [1, 1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmitReset = (formValues, data, submitSuccess, submitError) => {
    setCurrentFormValue({ ...formValues })
    setMessages([])
    dispatch(reset_password(formValues)).then(action => {
      // submitSuccess()
      if (action.type === "USER_RESET_PASSWORD_SUCCESS") {
        setMessages([
          {
            status: "success",
            value:
              "Les instructions détaillées de connexion ont été envoyées à votre adresse électronique.",
          },
        ])
      }
      if (action.type === "USER_RESET_PASSWORD_FAIL") {
        submitError()
        setMessages([
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {[...messages].map((message, key) => (
        <Message
          key={message.value}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages([...messages.filter((value, index) => index !== key)])
          }
        >
          {message.value}
        </Message>
      ))}
      <Form data={formDatareset} onSubmit={onSubmitReset} />
    </>
  )
}

export default connect()(ResetPassword)
