import React, { useState } from "react"
import { Flex, Box, Button } from "rebass/styled-components"
import Form from "../Form"
import ModalCustom from "../UI/Modal"
import ResetPassword from "./ResetPassword"
import Message from "../UI/Message"
import { getErrorMessage } from "../../utils"

import { connect } from "react-redux"
import { login } from "../../redux/actions/currentUserActions"

const Connexion = ({ dispatch }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState([])
  const [currentFormValue, setCurrentFormValue] = useState({})
  const formData = {
    formId: "connexion",
    successMessage: "Connexion réussie.",
    loadingMessage: "Connexion en cours...",
    submitLabel: "Connexion",
    elements: [
      {
        type: "text",
        name: "username",
        default: currentFormValue.username ? currentFormValue.username : "",
        label: "Nom d'utilisateur ou email",
        required: true,
        props: {
          width: "100%",
        },
      },
      {
        type: "password",
        name: "password",
        default: "",
        label: "Mot de passe",
        required: true,
        props: {
          width: "100%",
        },
      },
      {
        type: "checkbox",
        name: "keeploggedin",
        default: currentFormValue.keeploggedin
          ? parseInt(currentFormValue.keeploggedin)
          : 1,
        label: "",
        required: false,
        props: {
          width: "100%",
        },
        options: [
          {
            value: 1,
            label: "Rester connecté",
          },
        ],
      },
      {
        type: "markup",
        markup: (
          <Button
            as="span"
            variant="primaryOutline"
            width={1}
            onClick={() => setIsOpen(true)}
          >
            Mot de passe oublié
          </Button>
        ),
        props: {
          width: [1, 1 / 2],
        },
      },
      {
        type: "submit",
        label: "Connexion",
        props: {
          width: [1, 1 / 2],
        },
        buttonProps: {
          width: 1,
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues, data, submitSuccess, submitError) => {
    setCurrentFormValue({ ...formValues })
    const keeploggedin = formValues.keeploggedin === "1"
    delete formValues.keeploggedin
    setMessages([])
    dispatch(login(formValues, keeploggedin)).then(action => {
      if (action.type === "USER_LOGIN_FAIL") {
        setMessages([
          {
            status: "error",
            value: getErrorMessage(action.error.response),
          },
        ])
        submitError()
      }
    })
  }
  return (
    <Box>
      {messages.map((message, key) => (
        <Message
          key={message.value}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      {/* {loading && <Loader message="Connexion en cours" />} */}

      <Form data={formData} onSubmit={onSubmit} />
      <ModalCustom
        isOpen={isOpen !== false}
        title="Mot de passe oublié"
        setIsOpen={setIsOpen}
      >
        <Flex justifyContent="center">
          <Box width={[1, 1 / 2]} py={4} textAlign="center">
            <ResetPassword />
          </Box>
        </Flex>
      </ModalCustom>
    </Box>
  )
}

export default connect()(Connexion)
